import { shortenNumber } from "_common/utils";
import Icon              from "_components/atoms/icon";
import Typography        from "_components/atoms/typography";
import useStyles         from "_styles/atoms/viewCount";

export default function ViewCount ( { viewCount, containerClass = "", iconClass = "", textClass = "" } ) {
	const { classes } = useStyles ( undefined, { name: "styles" } );

	return (
		<div className = { `${ classes.viewTagContainer } ${ containerClass }` }>
			<Icon sameInBothTheme
				className = { `${ classes.eyeIcon } ${ iconClass }` }
				name = "eyeIcon"
			/>

			<Typography className = { `${ classes.viewTagText } ${ textClass }` }
				title = { shortenNumber ( viewCount ) }
				variant = "subtitle1"
			/>
		</div>
	);
}
