import { Carousel }         from "@mantine/carousel";
import { useRef, useState } from "react";

import { storeEvent }       from "_analytics/index";
import { useGetDeviceType } from "_common/hooks/global";
import Icon                 from "_components/atoms/icon";

import useStyles from "./styles";
import Card      from "./videoCard";

import "@mantine/carousel/styles.css";

const CustomCarousel = ( { data } ) => {
	const { classes }         = useStyles ();
	const containerRef        = useRef ();
	const deviceType          = useGetDeviceType ();
	const [ embla, setEmbla ] = useState ( null );

	const isMobile         = deviceType === "mobile";
	const isTablet         = deviceType === "tablet";
	const isMobileOrTablet = isMobile || isTablet;

	let slideSize = "35";

	if ( isTablet )
		slideSize = "40";

	if ( isMobile )
		slideSize = "95";

	const [ currentSlide, setCurrentSlide ] = useState ( 0 );

	const handleGAEvent = label => {
		storeEvent ( "Home_carousel", "Swiped", label );
	};

	return (
		<div ref = { containerRef }>
			<Carousel
				loop
				className = { classes.carouselContainer }
				classNames = { { slide: classes.slide, controls: classes.controls, control: classes.control } }
				draggable = { isMobileOrTablet }
				getEmblaApi = { setEmbla }
				initialSlide = { 0 }
				nextControlIcon = { (
					<Icon
						sameInBothTheme
						className = { classes.controlIcon }
						name = "carouselRight"
					/>
				) }
				onNextSlide = { () => handleGAEvent ( "right_swipe" ) }
				onPreviousSlide = { () => handleGAEvent ( "left_swipe" ) }
				onSlideChange = { idx => setCurrentSlide ( idx ) }
				previousControlIcon = { (
					<Icon
						sameInBothTheme
						className = { classes.controlIcon }
						name = "carouselLeft"
					/>
				) }
				slideGap = "xl"
				slideSize = { slideSize + "%" }
				styles = { {
					control: {
						backgroundColor : "rgba(0,0,0,0.5) !important",
						border          : "none !important",
						borderRadius    : "50%"
					}
				} }
			>

				{data.map ( ( item, idx ) => {
					return (
						<Carousel.Slide key = { idx }>
							<Card
								currentSlide = { currentSlide }
								data = { item }
								index = { idx }
								isActive = { idx == currentSlide }
								lastIndex = { data.length - 1 }
								scrollNext = { embla?.scrollNext }
								scrollPrev = { embla?.scrollPrev }
							/>
						</Carousel.Slide>
					);
				}
				)}
			</Carousel>
		</div>
	);
};

export default CustomCarousel;
